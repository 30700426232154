import React, {useEffect, useState } from 'react';
import './App.css';
import firebaseApp from './firebaseConnect';
import { getDatabase, ref } from 'firebase/database';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import MentorForm from './MentorForm';
import logo from './xiv.svg';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const authProvider = new GoogleAuthProvider();
const database = getDatabase(firebaseApp);
const databaseRef = ref(database, 'mentorRoulette')

function App() {
  const auth = getAuth(firebaseApp);
  const [isSignedIn, setIsSignedIn] = useState(false);
  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, (user: any) => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [auth]);

  const handleSignIn = async () => {
    try {
      await signInWithPopup(auth, authProvider);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  }

  
  if (!isSignedIn) {
    return (
      <div>
        <nav className="nav">
          <div className="nav-left">
          <div className="flex">
            <img className="nav-brand" src={logo} alt="" />
            <div className="brand-text">Mentor Tracker</div>
          </div>
          </div>
          <div className="nav-right hide-tablet-down">
            <div className="nav-item">Please Sign In</div>
          </div>
        </nav>
        <div className="container">
          <button onClick={handleSignIn} className="button is-solid m-t-10" >Sign In To Google</button>
        </div>
      </div>
    );
  }
  return (
    <div>
      <nav className="nav">
        <div className="nav-left">
          <div className="flex">
            <img className="nav-brand" src={logo} alt="" />
            <div className="brand-text">Mentor Tracker</div>
          </div>
        </div>
        <div className="nav-responsive">
          <div className="nav-right">
            <div className="nav-item hide-tablet-down">
              {auth?.currentUser?.displayName}
            </div>
            <div onClick={() => auth.signOut()} className="nav-item hide-tablet-down logout-button" role="button">
              <i className="d-icon d-exit-to-app"></i>
              <span className="icon-name">Sign Out</span>
            </div>
          </div>
        </div>
      </nav>
      <div className="container">
        <div className="m-t-20">
          <MentorForm databaseRef={databaseRef} />
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
}

export default App;
